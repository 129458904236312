import { makeStyles } from '@material-ui/styles';
import category from 'connectors/newport/menu/category';
import theme from '../../../theme';
import { getCMSSettings, getCMSSlot, getCMSConfig } from 'tools/cmsBlockSettings';
import BannerContent from '../text/banner-content';
import Image from 'next/image';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import urlNormalizer from 'tools/urlNormalization';
import CategoryMenu from '../menu/category-menu';

const useStyles = makeStyles(
	{
		wrapper: {
			display: 'flex',
			position: 'relative',
			overflow: 'hidden',
			[theme.breakpoints.down('sm')]: {
				height: 'auto !important'
			},
			'&.hide': {
				display: 'none'
			}
		},
		link: {
			display: 'block',
			position: 'absolute',
			top: 0,
			left: 0,
			bottom: 0,
			right: 0,
			zIndex: 10
		},
		desktopImages: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		desktopImageWrapper: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%'
		},
		mobileImages: {
			display: 'grid',
			gridTemplateRows: 'auto',
			gridTemplateColumns: 'auto',
			width: '100%',
			'& > *': {
				gridColumn: '1 / 2',
				gridRow: '1 / 2'
			},
			[theme.breakpoints.up('md')]: {
				display: 'none'
			}
		},
		image: {
			opacity: 0,
			transition: 'opacity 1s ease',
			[theme.breakpoints.up('md')]: {
				opacity: 1
			}
		},
		video: {
			width: '100%'
		},
		mobileVideo: {
			display: 'block',
			width: '100%',
			minHeight: '100vw',
			objectFit: 'cover'
		},
		content: {
			position: 'absolute',
			top: 0,
			left: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			padding: '0 15px',
			'& .pre-heading--thin': {
				display: 'block !important'
			}
		}
	},
	{ name: 'NewportMainBanner' }
);

export default function NewportMainBanner({ block, category, slots, cmsPage, categoryMenu, breadcrumbs }) {
	const classes = useStyles();
	const router = useRouter();
	const CMSSettings = getCMSSettings(block);
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
	const desktopImage = getCMSSlot('desktop-image', slots);
	const mobileImages = getCMSSlot('mobile-slider', slots);
	const textContent = getCMSSlot('overlay', slots);
	const [hasLoaded, setHasLoaded] = useState(false);
	const [active, setActive] = useState(1);

	useEffect(() => {
		setHasLoaded(true);
	}, []);

	useEffect(() => {
		if (!isDesktop) {
			setTimeout(() => {
				setActive(active + 1);
			}, 2500);
		}
	}, [isDesktop, active]);

	function getBannerLink() {
		return desktopImage.data.url || false;
	}

	function getMediaType(media) {
		return media && media.metaData !== null ? 'image' : 'video';
	}

	function getUrl(item) {
		return item.type === 'link'
			? item.linkType === 'external'
				? item.externalLink
				: '/' + item.internalLink
			: item.seoUrls && item.seoUrls.length > 0
			? '/' + item.seoUrls[0].seoPathInfo
			: '/navigation/' + item.id;
	}

	function urlNormalize(url) {
		return urlNormalizer(url, router.basePath);
	}

	function hasCategoryNavigationBlock() {
		let foundCategoryNavigation = false;
		cmsPage.sections.forEach((section) => {
			section?.blocks.forEach((block) => {
				if(block.type === 'category-menu') {
					foundCategoryNavigation = true;
				}
			});
		});

		return foundCategoryNavigation;
	}

	function getDesktopMedia() {
		let media = desktopImage.data.media;
		let config = desktopImage.translated.config;

		if (getMediaType(media) === 'image') {
			let image = {
				src: media.url
			};

			if (config.displayMode.value === 'cover') {
				image.layout = 'fill';
				image.objectFit = 'cover';
			} else {
				image.width = media.metaData.width;
				image.height = media.metaData.height;
			}

			return (
				<div
					className={classes.desktopImageWrapper}
					style={{ opacity: getCMSConfig('opacity', desktopImage.config) || 1 }}
				>
					<Image
						{...image}
						alt={category.metaTitle || category.name || 'Newport banner image'}
						className={[classes.image, classes.desktopImages].join(' ')}
						priority={true}
						unoptimized
					/>
				</div>
			);
		} else if (media) {
			let url = media.url;
			let videoProps = {};

			if (!hasLoaded) {
				videoProps.lazysrc = url;
			} else {
				videoProps.src = url;
			}

			return (
				<video
					{...videoProps}
					className={[classes.video, classes.desktopImages].join(' ')}
					style={{
						opacity: getCMSConfig('opacity', desktopImage.config),
						objectFit: config.displayMode.value === 'cover' ? 'cover' : 'contain'
					}}
					autoPlay
					playsInline
					loop
					muted
				/>
			);
		}
	}

	function getMobileImages() {
		let imageCount = mobileImages.data.sliderItems.length;

		return mobileImages.data.sliderItems.map((image, index) => {
			const type = getMediaType(image.media);

			if (type === 'image') {
				return (
					<div
						style={{
							opacity:
								imageCount === 1 || index === active % imageCount
									? getCMSConfig('opacity', desktopImage.config)
									: 0
						}}
						className={[
							classes.image,
							imageCount === 1 || index === active % imageCount ? 'active' : ''
						].join(' ')}
						key={index}
					>
						<Image
							src={image.media.url}
							alt={category.metaTitle || category.name || 'Newport banner image'}
							width={image.media.metaData.width}
							height={image.media.metaData.height}
							layout="responsive"
							key={index}
							priority={index === 0}
							unoptimized
						/>
					</div>
				);
			} else if (image.media) {
				let url = image.media.url;
				let videoProps = {};

				if (!hasLoaded) {
					videoProps.lazysrc = url;
				} else {
					videoProps.src = url;
				}

				return (
					<div
						style={{
							position: 'relative',
							opacity: getCMSConfig('opacity', desktopImage.config) || 1
						}}
						key={index}
					>
						<video {...videoProps} className={classes.mobileVideo} autoPlay playsInline loop muted />
					</div>
				);
			}
		});
	}

	return (
		<>
			<div
				className={`${classes.wrapper} ${CMSSettings.class}`}
				style={{
					marginTop: CMSSettings.styles.marginTop,
					height: desktopImage.translated.config.height.value,
					width: '100%',
					background:
						parseFloat(desktopImage.config.opacity.value) !== 1
							? CMSSettings.styles.backgroundColor || '#212121'
							: 'white'
				}}
			>
				{getBannerLink() ? (
					<Link href={urlNormalize(getBannerLink())}>
						<a className={classes.link} />
					</Link>
				) : (
					''
				)}

				<div className={classes.mobileImages}>{getMobileImages()}</div>
				{isDesktop ? getDesktopMedia() : ''}

				<div className={classes.content}>
					<BannerContent content={textContent.data.content} />
				</div>
			</div>

			{cmsPage.type === 'landingpage' && !hasCategoryNavigationBlock() ? (
				<CategoryMenu
					category={category}
					categoryMenu={categoryMenu}
					block={block}
					slots={slots}
					breadcrumbs={breadcrumbs}
					forceButton={true}
					hideSeeAll={true}
					cmsPage={cmsPage}
				/>
			) : (
				''
			)}
		</>
	);
}
