import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress, Container, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "../../tools/i18n";
import fetch from "react-storefront/fetch";
import { useRouter } from "next/router";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import theme from "../theme";
import ProductThumbnail from "./thumbnail";
import Link from "next/link";
import parsePrices from "functions/parseSWPrices";
import formatPrice from "functions/priceFormat";

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: 20,
		paddingBottom: 40
	},
	grid: {
		maxWidth: 800
	},
	title: {
		marginBottom: 20
	},
	link: {
		textDecoration: 'none',
		color: 'inherit'
	},
	product: {
		backgroundColor: 'white',
		boxShadow: theme.borderShadow,
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'center',
		},
	},
	image: {
		[theme.breakpoints.up('sm')]: {
			width: 125
		}
	},
	info: {
		padding: '8px 8px 8px 12px',
		[theme.breakpoints.down('md')]: {
			textAlign: 'center'
		}
	},
	name: {
		textDecoration: 'none',
		fontSize: 14,
		color: '#333'
	},
	prices: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		marginTop: 6,
		[theme.breakpoints.down('md')]: {
			marginTop: 8,
			marginBottom: 8,
			justifyContent: 'center'
		}
	},
	price: {
		fontSize: 14,
		color: '#666',
		'&.special': {
			color: '#ca0d0d'
		},
		'&.old': {
			marginLeft: 10,
			textDecoration: 'line-through',
			color: '#999'
		}
	},
}));

export default function PartialSlugSuggestion() {
	const classes = useStyles();
	const { t } = useTranslation();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const { query: { slug }, query } = useRouter();
	const [ products, setProducts ] = useState([]);

	function buildQuery(query) {
		const term = '?term=' + query.slug?.reverse().map(term => {
			return term.split('-').join(' ')
		}).slice(0, -1).join(' ') + ' ' + query?.utm_term;

		return term;
	}

	useEffect(async () => {
		if (!slug) {
			return;
		}

		const partial = buildQuery(query);
		const url = [ '/api/product/get/search' + partial ].join('/');

		const response = await fetch(url, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			credentials: 'include'
		});

		const result = await response.json();
		if (result.status === 'success') {
			if (result.products) {
				setProducts(result.products);
			}
		}
	}, []);

	function getProductUrl(product) {
		if (product.seoUrls) {
			return '/' + product.seoUrls[0].seoPathInfo;
		} else {
			return '/detail/' + product.id;
		}
	}

	function getProductPrices(product) {
		let prices = product.calculatedPrices && product.calculatedPrices.length > 0 ? product.calculatedPrices[0] : product.calculatedPrice
		return parsePrices(prices);
	}

	return (
		products && products.length > 0 ?
			<Container className={classes.wrapper} disableGutters>
				<Typography className={classes.title} variant="h3">{t('404-suggestion')}</Typography>
				<Grid className={classes.grid} spacing={isMobile ? 1 : 3} container>
					{products.map((product, index) => {
						const prices = getProductPrices(product);

						return (
							<Grid className={classes.item} xs={6} key={index} item>
								<Link href={getProductUrl(product)}>
									<a className={classes.link}>
										<div className={classes.product}>
											<div className={classes.image}><ProductThumbnail image={product.cover}
																							 size={550} index={1}/>
											</div>
											<div className={classes.info}>
												<Typography
													className={classes.name}>{product.translated.name}</Typography>
												<div className={classes.prices}>
													{prices.isOnSale ?
														<>
															<span className={[ classes.price, 'special' ].join(' ')}>{formatPrice(prices.price)}</span>
															<span className={[ classes.price, 'old' ].join(' ')}>{formatPrice(prices.listPrice)}</span>
														</> : <span className={classes.price}>{formatPrice(prices.price)}</span>
													}
												</div>
											</div>
										</div>
									</a>
								</Link>
							</Grid>
						)
					})}
				</Grid>
			</Container> : ''

	);
}
