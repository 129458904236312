import React from 'react';
import { Container, Grid } from '@material-ui/core';
import theme from '../theme';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
	wrapper: {
		'&.anni-frame': {
			position: 'relative',
			padding: 25,
			'&:before': {
				content: '""',
				position: 'absolute',
				top: 20,
				left: 20,
				right: 20,
				bottom: 20,
				zIndex: 100,
				pointerEvents: 'none',
				backgroundClip: 'text',
				'-webkit-text-fill-color': 'transparent',
				'-webkit-background-clip': 'text',
				backgroundColor: '#B99354',
				backgroundImage: 'linear-gradient(180deg, #FBD7A2, #B99354)',
				border: '1px solid #B99354',
				borderImageSlice: 1,
				borderImageSource: 'linear-gradient(180deg, #FBD7A2, #B99354)',
			},
			[theme.breakpoints.down('sm')]: {
				'&:before': {
					top: 25,
					left: 15,
					right: 15,
					bottom: 25,
				}
			}
		},
		'&.anni-frame-no-padding': {
			padding: 0
		},
		'&.anni-bg': {
			backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,1)) ,url(/images/bg/black-week-background-2000x1100.jpg)',
			backgroundPosition: 'center top !important',
			backgroundRepeat: 'repeat-x !important',
			[theme.breakpoints.down('sm')]: {
				backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,1)) ,url(/images/bg/black-week-background-1080x1080.jpg)',
				backgroundPosition: 'center top !important',
				backgroundRepeat: 'repeat-x !important',
				'&:before': {
					top: 25,
					left: 15,
					right: 15,
					bottom: 25,
				}
			}
		},
		'&.bg-vertical-bottom': {
			backgroundPositionY: 'bottom !important',
		},
		'&.bg-horizontal-bottom': {
			backgroundPositionX: 'center !important'
		},
		'&.bg-flower-bottom': {
			position: 'relative',
			marginBottom: -52,
			paddingBottom: 250,
			[theme.breakpoints.down('sm')]: {
				marginBottom: -29,
				paddingBottom: 150,
			}
		},
		'&.bg-contain': {
			backgroundSize: '500px !important',
			[theme.breakpoints.down('sm')]: {
				backgroundSize: '275px !important'
			}
		},
		'.eu &.hide-eu': {
			display: 'none'
		},
		'.sv &.hide-sv': {
			display: 'none'
		},
		'.no &.hide-no': {
			display: 'none'
		},
		'.fi &.hide-fi': {
			display: 'none'
		},
		'.dk &.hide-dk': {
			display: 'none'
		},
		'.nl &.hide-nl': {
			display: 'none'
		}
	},
	container: {
		'.customMaxWidth &': {
			maxWidth: 835,
			paddingLeft: 0,
			paddingRight: 0
		},
		'.noPadding &': {
			[theme.breakpoints.up('md')]: {
				paddingLeft: 0,
				paddingRight: 0
			}
		},
	},
	section: {
		'& .campaign': {
			padding: '40px 0',
			flexBasis: 'auto',
			maxWidth: 'none'
		}
	},
	inner: {
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 0,
			paddingRight: 0
		},
		[theme.breakpoints.down('md')]: {
			width: '100% !important',
			margin: '0px !important'
		}
	}
}, { name: 'Section' });

function Section({ section, sizeMode, children }) {
	const classes = useStyles();
	const type = section.type;
	let sizeingMode = !!sizeMode && !section.cssClass?.includes('compact') ? sizeMode : section.sizingMode;
	const gutters = { nogutters: 0, small: 2, normal: 5, large: 8, xlarge: 10 };

	if(section.cssClass === 'boxed') {
		sizeingMode = 'boxed';
	}

	let gutter =
		!type?.includes('grid') && type !== 'sidebar'
			? 'nogutter'
			: section.customFields && !!section.customFields.gutter
			? section.customFields.gutter
			: 'normal';

	function renderSectionType(children) {
		return (
			<Grid spacing={gutters[gutter]} className={classes.inner} container>
				{type === 'sidebar' ? (
					<Grid item xs={12} md={4} lg={3}>
						{renderSectionChildren('sidebar', children)}
					</Grid>
				) : type?.includes('grid') ? (
					<>
						<Grid item xs={12} sm={6} md={type === 'grid2' ? 6 : 4}>
							<div className="section-grid-col1">{renderSectionChildren('col1', children)}</div>
						</Grid>
						<Grid item xs={12} sm={6} md={type === 'grid2' ? 6 : 4}>
							<div className="section-grid-col3">{renderSectionChildren('col2', children)}</div>
						</Grid>
						{type === 'grid3' ? (
							<Grid item xs={12} sm={6} md={4}>
								<div className="section-grid-col3">{renderSectionChildren('col3', children)}</div>
							</Grid>
						) : (
							''
						)}
					</>
				) : (
					''
				)}
				{!type?.includes('grid') ? (
					<Grid
						xs={12}
						md={type === 'sidebar' ? 8 : 12}
						lg={type === 'sidebar' ? 9 : 12}
						spacing={getGridSpacing()}
						container
						item
					>
						{renderSectionChildren('main', children)}
					</Grid>
				) : (
					''
				)}
			</Grid>
		);
	}

	function renderSectionChildren(position, children) {
		return children.filter(child => {
			return child.props.block.sectionPosition === position;
		});
	}

	function getGridSpacing() {
		let spacing = 0;

		if (section.cssClass && section.cssClass.indexOf('slim-aarons') >= 0) {
			spacing = 8;
		}

		return spacing;
	}

	return (
		<div
			className={[classes.wrapper, section.cssClass, 'bg-' + section.backgroundMediaMode].join(' ')}
			style={{
				backgroundColor: section.backgroundColor || 'transparent',
				backgroundImage: section.backgroundMedia ? 'url(' + section.backgroundMedia.url + ')' : '',
				backgroundPosition: 'center top',
				backgroundSize: section.backgroundMediaMode,
				backgroundRepeat: 'no-repeat'
			}}
		>
			{sizeingMode === 'full_width' ? (
				renderSectionType(children)
			) : (
				<Container
					disableGutters={!!section.cssClass && section.cssClass?.includes('gutterless')}
					className={classes.container}
					maxWidth={
						!!section.cssClass && section.cssClass?.includes('campaign')
							? 'xl'
							: !!section.cssClass && section.cssClass?.includes('compact')
							? 'sm'
							: 'lg'
					}
				>
					{renderSectionType(children)}
				</Container>
			)}
		</div>
	);
}

export default Section;
