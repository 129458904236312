import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import LazyHydrate from 'tools/lazyHydrate';
import theme from '../theme';
import Select, { components } from 'react-select';
import Link from 'next/link';
import FilterDropdown from './filterDropdown';
import FilterRange from './filterRange';
import { useTranslation } from 'tools/i18n';
import { useRouter } from 'next/router';
import { Button } from "@material-ui/core";
import SlimHome from "../../public/images/icons/slim_aarons_home.svg";

const useStyles = makeStyles(
	{
		wrapper: {
			display: 'flex',
			justifyContent: 'center',
			width: '100%',
			margin: '10px 0 40px',
			'&.masonry': {
				width: 'auto',
				margin: 0,
				flexGrow: 1
			},
			[theme.breakpoints.down('lg')]: {
				marginBottom: 20
			}
		},
		inner: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'flex-start',
			width: '100%',
			maxWidth: theme.maxWidth,
			[theme.breakpoints.down('sm')]: {
				flexWrap: 'wrap'
			},
			'&.masonry': {
				[theme.breakpoints.up('md')]: {
					flexDirection: 'column',
					alignItems: 'center',
				}
			}
		},
		collectionsLink: {
    		display: 'flex',
    		alignItems: 'stretch',
    		marginBottom: 10,
			textDecoration: 'none',
			[theme.breakpoints.down('sm')]: {
				width: 'calc(50% - 5px)',
			}
		},
		collectionsButton: {
			height: 38,
			padding: '4px 12px',
			borderRadius: 0,
			backgroundColor: '#f0f0f0',
			fontFamily: '"Futura","Helvetica","Arial",sans-serif',
    		fontSize: 14,
    		fontWeight: 400,
			lineHeight: 1,
    		color: '#000',
    		textTransform: 'none',
			'& > span': {
				display: 'flex',
				alignItems: 'center',
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				'& > span': {
					justifyContent: 'space-between',
					flexDirection: 'row-reverse'
				}
			}
		},
		homeIcon: {
			marginRight: 10,
			color: '#ccc',
			strokeWidth: 0.65,
			[theme.breakpoints.down('sm')]: {
				width: 14,
				marginRight: -1.5,
				strokeWidth: 1.2
			}
		},
		filters: {
			display: 'flex',
			flexWrap: 'wrap',
			alignItems: 'center',
			justifyContent: 'space-between',
			flexGrow: 1,
			order: 10,
			width: '100%',
			margin: '10px 0 0',
			'& > div': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'stretch',
				width: 'calc(50% - 5px)',
				marginBottom: 10
			},
			[theme.breakpoints.up('sm')]: {
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				width: '100%',
				margin: 0,
				gap: 20,
				'& > div': {
					width: 'auto',
					maxWidth: 300,
					flexGrow: 1
				},
				'&.masonry': {
					justifyContent: 'center'
				}
			},
			[theme.breakpoints.up('md')]: {
				order: 0,
			}
		},
		searchWrapper: {
			width: 'calc(50% - 5px)',
			[theme.breakpoints.up('md')]: {
				width: 350
			}
		},
		filterTrigger: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			minHeight: '38px',
			width: 'calc(50% - 5px)',
			padding: '2px 10px',
			border: 'none',
			textAlign: 'left',
			...theme.typography.dropdown,
			backgroundColor: 'white',
			outline: 'none',
			boxShadow: theme.boxShadow.dropdown,
			[theme.breakpoints.up('sm')]: {
				width: 'auto',
				minWidth: 220
			},
			'& svg': {
				display: 'inline-block',
				fill: 'currentColor',
				lineHeight: 1,
				stroke: 'currentColor',
				strokeWidth: 0,
				color: '#ccc'
			},
			'&.open': {
				'& svg': {
					color: '#666',
					transform: 'rotateZ(-90deg)'
				}
			}
		},
		sorting: {
			display: 'flex',
			justifyContent: 'flex-end',
			alignItems: 'center',
			width: 'calc(50% - 5px)',
			'& label': {
				marginRight: 15
			},
			[theme.breakpoints.up('sm')]: {
				width: 'auto',
				flexGrow: 1
			}
		},
		sortingDropdown: {
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				width: 'auto',
				minWidth: 150,
				'&.comfortable': {
					flexGrow: 1
				}
			}
		}
	},
	{ name: 'productFilter' }
);

function ProductFilters({
	filters,
	availableFilters,
	currentFilters,
	categories,
	onFilterChange,
	category,
	isMasonry
}) {
	const router = useRouter();
	const { t } = useTranslation(['general', 'attributes']);
	const classes = useStyles();
	const [state, setState] = useState({
		filterOpen: true
	});

	function toggleFilterOpen() {
		setState({
			...state,
			filterOpen: !state.filterOpen
		});
	}

	function renderFilters(availableFilters) {
		let filterKeys = Object.keys(availableFilters);
		const filters = [];

		if(isMasonry && filterKeys.indexOf('manufacturer') >= 0) {
			filterKeys.splice(filterKeys.indexOf('manufacturer'), 1);
		}

		filterKeys.forEach(filterKey => {
			let filter = availableFilters[filterKey];
			if (filter.type === 'dropdown') {
				filters.push(renderDropdownFilter(filter, filterKey));
			} else if (filter.type === 'range' && !isMasonry) {
				filters.push(renderRangeFilter(filter, filterKey));
			}
		});

		return filters;
	}

	function isPropertyKey(key) {
		return availableFilters?.properties?.entities.find(property => property.translated.customFields?.attributeCode === key);
	}

	function renderDropdownFilter(filter, name) {
		let key = category.id + name;

		return (
			<FilterDropdown
				name={name}
				label={filter.label}
				currentFilter={isPropertyKey(name) ? currentFilters['properties'] : currentFilters[name]}
				options={filter.options}
				onChange={onFilterChange}
				key={key}
				className={[classes.sortingDropdown, isMasonry ? 'comfortable' : ''].join(' ')}
			/>
		);
	}

	function renderRangeFilter(filter, name) {
		let key = category.id + name;

		return (
			<FilterRange
				name={name}
				currentFilter={currentFilters[name]}
				min={parseFloat(filter.min)}
				max={parseFloat(filter.max)}
				onChange={onFilterChange}
				key={key}
			/>
		);
	}

	function Option({ children, ...props }) {
		if (props.data.value === getCurrentCategory()) {
			props.isSelected = true;
		}

		return <components.Option {...props}>{children}</components.Option>;
	}

	function categoryLabel({ children, ...props }) {
		return <span>{t('attributes:categories')}</span>;
	}

	function getFilterableCategories() {
		if (!categories || categories.length <= 0) {
			return [];
		}

		return categories.filter(category => category.type === 'page');
	}

	function getCategoryOptions() {
		let filterableCategories = getFilterableCategories();
		return filterableCategories.map(category => {
			return {
				label: category.name,
				value: category.url
			};
		});
	}

	function getCurrentCategory() {
		return router.asPath.split('?')[0];
	}

	function renderFilter() {
		return <div className={[classes.wrapper, isMasonry ? 'masonry' : ''].join(' ')}>
			<div className={[classes.inner, isMasonry ? 'masonry' : ''].join(' ')}>
				<div className={[classes.filters, isMasonry ? 'masonry' : ''].join(' ')}>
					{isMasonry ? <Link href={'/shop/slim-aarons'}><a className={classes.collectionsLink}><Button className={classes.collectionsButton}><SlimHome className={classes.homeIcon} width={20}/> Slim Aarons</Button></a></Link> : ''}
					{getFilterableCategories() && getFilterableCategories().length > 0 ? (
						<Select
							options={getCategoryOptions()}
							components={{
								IndicatorSeparator: null,
								Placeholder: categoryLabel,
								Option: Option
							}}
							onChange={data => {
								router.push(data.value);
							}}
							value={getCurrentCategory()}
							isSearchable={false}
							controlShouldRenderValue={false}
							className={classes.sortingDropdown}
							styles={{
								control: (provided, state) => {
									delete provided.borderWidth;
									delete provided.borderStyle;
									delete provided.borderColor;

									return {
										...provided,
										...theme.typography.dropdown,
										borderRadius: 0,
										backgroundColor: '#f0f0f0',
										boxShadow: 'none',
										[theme.breakpoints.up('sm')]: {
											minWidth: 150
										}
									};
								},
								menu: (provided, state) => {
									return {
										...provided,
										boxShadow: theme.boxShadow.dropdownActive
									};
								}
							}}
							instanceId="category-select"
						/>
					) : (
						''
					)}
					{renderFilters(filters).map((filter, key) => filter)}
				</div>
			</div>
		</div>
	}

	return (
		isMasonry ? renderFilter() : <LazyHydrate id="filters" on="visible">{renderFilter()}</LazyHydrate>
	);
}

export default ProductFilters;
